.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@font-face {
  font-family: 'digitalism_roundregular';
  src: url('fonts/d3digitalismround-yzry-webfont.woff2') format('woff2'),
       url('fonts/d3digitalismround-yzry-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'arcade_classic';
  src: url('fonts/arcadeclassic-ov2x-webfont.woff') format('woff'),
       url('fonts/arcadeclassic-ov2x-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}





html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
* {
  box-sizing: border-box;
}

body{
  color:#50bec5;
  font-family:"Courier New";
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(77,10,10,1) 0%, rgba(3,44,52,1) 98%);
  background-size: cover;
  background-attachment: fixed;
}

/*Scanline effect*/
body::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 100;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

h1{
  font-family: 'arcade_classic';
  font-weight: normal;
  padding: 50px 0;
  margin: 0;
  font-size: 40pt;
  text-align: center;
  line-height: 50pt;
}

h2{
  font-family: 'digitalism_roundregular';
  text-align: center;
}

footer{
  position: sticky;
  bottom: 0;
  margin-top: 50px;
  text-align: right;
  padding: 0 50px 30px 0;
}

.navContainer{
  font-size: 18pt;
  width: 550px;
  margin: 0 auto 30px auto;
}

nav ul{
  list-style: none;
  padding: 0;
}

nav li{
  display: inline;
  padding: 0 8px;
}

.clear{
  clear: both
}

.clearfix:after{
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.box{
  border: 3px solid #50bec5;
  width: 80%;
  display: block;
  margin: 100px auto;
  text-align: center;
  font-size: x-large;
}

input{
  margin: 50px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

a{
  color:#50bec5; 
}

a:visited{
  color:#529094;
}

a:hover{
  color: #fff  
}

.fileFolder{
  opacity: .8;
  height: 200px;
  width: 400px;
  margin: 50px;
  float: left;
  cursor: pointer;
  transition: opacity .25s;
}

.fileFolder.denied{
  cursor: not-allowed;
}

.fileFolder:hover{
  opacity: 1;
  transition: opacity .25s;
}

.container{
  margin: 80px auto;
  width: 1500px;
}

.tabContainer{
  width: 100%;
  height: 30px;
}

.folderBackContainer{
  float: left;
  height: 30px;
  width: calc(100% - 80px);
}

.folderSpacer{
  height: 15px;
  width: 100%;
}

.folderBack{
  height: 15px;
  width: 100%;
  border-radius: 0 5px 0 0;
}

.fileTab{
  float: left;
  height: 30px;
  width: 80px;
  border-radius: 5px 5px 0 0;
}

.fileTab, .folderBack{
  background-color: #529094;
}

.denied .fileTab, .denied .folderBack{
  background-color: #7c2e2e;
}

.folderContents{
  height: 170px;
  width: 100%;
  background-color: #40cbd4;
  border-radius: 0 0 5px 5px;
}

.denied .folderContents {
  background-color: #ff002b;
}

.folderContents img{
  float: left;
  height: 133px;
  width: 100px;
  margin: 15px;
}
  
.folderContents span{
  float: left;
  font-size: 18pt;
  color: #000;
  margin: 25px;
}


/*ABOUT PAGE*/

.aboutBoxes{
  float: left;
}

.bioContainer p{
  margin-left: 30px;
  font-size: 16px;
}

.table{
  display: table;
}

.infoTable{
  float: left;
  width: 500px;
}

.aboutPhoto{
  float: right;
}

.aboutPhoto img{
  height: 380px;
}

.infoTable, .aboutPhoto{
  margin: 30px;
  border: 1px solid #50bec5;
  padding: 5px;
}

.innerTable{
  width: 100%;
}

.rowFill{
  background-color: #50bec5;
  color: black;
}

.tr{
  display: table-row;  
}

.td{
  display: table-cell;
  padding: 5px;
}
  
.sfTable .tr .td:first-child{
  width: 50%;
}


/*Contact form*/
.formContainer{
  margin: 30px;
  border: 1px solid #50bec5;
  padding: 5px;
}

.formSubcontainer{
  margin: 0 auto;
  width: 500px;
}

input,select,textarea{
  font-family:"Courier New";
  color: #000;
  border: 3px solid;
  border-color: #50bec5;
  padding: 10px;
  margin: 15px auto 30px auto;
  width: 500px;
}

legend{
  font-size: 30pt;
  text-align: center;
  padding-bottom: 15px;
}

/* Resume Page*/

.resumeBoxes{
  float: left;
  width: 30%;
}

.resumeJobs{
  float: right;
  width: 70%;
}

.resumeInfoTable{
  width: calc(100% - 60px);
  margin: 30px 30px 0 30px;
  border: 1px solid #50bec5;
  padding: 5px;
}

/* Contact Entries Page*/

.entriesContainer{
  border: 3px solid #50bec5;
  margin: 0 auto;
  padding: 10px;
  width: 818px;
}


/*splash screen */

.splash{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(images/binary-hr.gif);
  background-size: cover;
  z-index: 90;
  color: #ff002b;
  text-align: center;
  line-height: 90vh;
  font-weight: normal;
}

.splash.display-none{
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
}

.splash-text{
  width: 900px;
  height: 100px;
  margin: 50px auto;
  display: block;
}

.splash-text h1{
  position: absolute;
  top:40%;
  text-align: center;
}




@keyframes fade1{
  0%   {opacity: 0;}
  10%  {opacity: 1;}
  20%  {opacity: .5;}
  30%  {opacity: 1;}
  40%  {opacity: 0;}
  50%  {opacity: 0;}
  60%  {opacity: 0;}
  70%  {opacity: 0;}
  80%  {opacity: 0;}
  90%  {opacity: 0;}
  100% {opacity: 0;}
}

@keyframes fade2{
  0%   {opacity: 0;}
  10%  {opacity: 0;}
  20%  {opacity: 0;}
  30%  {opacity: 0;}
  40%  {opacity: 1;}
  50%  {opacity: .5;}
  60%  {opacity: 1;}
  70%  {opacity: 0;}
  80%  {opacity: 0;}
  90%  {opacity: 0;}
  100% {opacity: 0;}
}

@keyframes fade3{
  0%   {opacity: 0;}
  10%  {opacity: 0;}
  20%  {opacity: 0;}
  30%  {opacity: 0;}
  40%  {opacity: 0;}
  50%  {opacity: 0;}
  60%  {opacity: 0;}
  70%  {opacity: 1;}
  80%  {opacity: .5;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}

h1.fade-01{
  opacity: 0;
  animation: fade1 10s ease-in forwards;
}

h1.fade-02{
  opacity: 0;
  animation: fade2 10s ease-in forwards;
}

h1.fade-03{
  opacity: 0;
  animation: fade3 10s ease-in forwards;
}

